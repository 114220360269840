<template>
    <router-view />
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        created() {
            
        },
  //       watch: {
		// 	$route(to) {
		// 		// console.log('111',to.path)
		// 		let token = localStorage.getItem('token')
		// 		if(!token) {
		// 		    this.$api.login({},true).then(res => {
		// 		        this.$store.commit('setToken',res.data.token)
		// 		        this.$store.dispatch('getCartList')
		// 		        this.$router.push({
		// 		            path: '/'
		// 		        })
		// 		        // this.$message({
		// 		        //     message: res.message,
		// 		        //     type: 'success'
		// 		        // })
		// 		    })
		// 		}
		// 	}
		// },
        methods: {
            
        }
    }
</script>

<style lang="scss">
    html,
    body {
        padding: 0;
        margin: 0;
    }
    
    #app {
        width: 100%;
        min-height: 100vh;
        margin: 0 auto;
        font-family: Arial;
        background-color: #FFFFFF;
        
        // PC
        @media only screen and (min-width: 1024px) {
            .main {
                width: 100%;
                min-height: $container-height;
                box-sizing: border-box;
                padding: 0 $box-padding;
                background-color: #FFFFFF;
                
                .main-wrapper {
                    width: 100%;
                    max-width: $max-width;
                    margin: 0 auto;
                }
            }
        }
        
        // 手机
        @media only screen and (max-width: 1024px) {
            .main {
                width: 100%;
                box-sizing: border-box;
                // padding: 0 $h5-box-padding;
                background-color: #FFFFFF;
                
                .main-wrapper {
                    width: 100%;
                }
            }
        }
        
        .article-layout {
            min-height: 100vh !important;
        }
        
        .loading-mask {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100vh;
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, 0.2);
            
            .loading-icon {
                width: 3.2rem;
                height: 3.2rem;
                cursor: wait;
            }
        }
    }
</style>
