import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		token: localStorage.getItem('token') || null,
        loading: false,
        coinList: [],
        curCoin: {
            index: '',
            key: '',
            name: '',
            rate: 0
        },
        category: [],
        cartList: [],
        cartTotalNum: 0,
        cartTotalPrice: 0,
        contactList: []
	},

	mutations: {
        setToken(state, token) {
        	state.token = token
            localStorage.setItem('token',token)
        },
        
        logout(state) {
            localStorage.removeItem('token')
            state.token = null
        },
        
        setLoading(state, bool) {
            state.loading = bool
        },
        
        setCurCoin(state, data) {
            state.curCoin = data
            Vue.prototype.$currencySymbol = state.curCoin.name
            localStorage.setItem('coin_symbol', state.curCoin.name)
            localStorage.setItem('coin_index', state.curCoin.index)
            localStorage.setItem('cur_coin', JSON.stringify(state.curCoin))
        },
        
        initCartList(state, data) {
            state.cartList = []
            state.cartTotalNum = 0
            state.cartTotalPrice = 0
        },
        
        setCartList(state, params) {
            // if(params.type == 'add') {
            //     let cart = state.cartList.find(item => item.id == params.data.id)
            //     if(cart) {
            //         state.cartList = state.cartList.map(item => {
            //             if(item.id == cart.id) {
            //                 item.num += cart.num
            //             }
            //             return item
            //         })
            //     } else {
            //         state.cartList.unshift(params.data)
            //     }
            // } 
            if(params.type == 'set') {
                state.cartList = params.data
            } else if(params.type == 'edit') {
                state.cartList = state.cartList.map(item => {
                    if(item.id == params.data.id) {
                        item.num = params.data.num
                    }
                    return item
                })
            } else if(params.type == 'delete') {
                state.cartList = state.cartList.filter(item => item.id != params.data.id)
            }
            
            state.cartTotalNum = state.cartList.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.num
            },0)
            
            let total_price = state.cartList.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue.per_price) * currentValue.num
            },0)
            state.cartTotalPrice = total_price.toFixed(2)
        }
	},

	actions: {
        getCoinList({ commit, state }) {
            return Vue.prototype.$api.coins({},false,300000).then(res => {
                state.coinList = res.data.map((item,index) => {
                    item.index = index
                    return item
                })
                
                let cur_coin = localStorage.getItem('cur_coin')
                if(cur_coin) {
                    commit('setCurCoin', JSON.parse(cur_coin))
                } else {
                    commit('setCurCoin', state.coinList[0])
                }
                return Promise.resolve(res.data)
            })
        },
        
        getCategory({ commit, state }) {
            return Vue.prototype.$api.category({},false,180000).then(res => {
                state.category = res.data
                return Promise.resolve(res.data)
            })
        },
        
		getCartList({ commit, state }) {
		    return Vue.prototype.$api.cart_list().then(res => {
		        commit('setCartList', {
                    type: 'set',
                    data: res.data
                })
                return Promise.resolve(res.data)
		    })
		},
        
        getContactList({ commit, state }) {
            return Vue.prototype.$api.setting({
                slug: 'contact'
            },false,600000).then(res => {
                state.contactList = JSON.parse(res.data)
                return Promise.resolve(res.data)
            })
        }
	}
})

export default store
