const base_url = 'https://a-antares.shop' // 接口地址
const upload_url = base_url + '/api/system/file'

const get_urls = {
	countries: '/api/countries', // 获取国家
    states: '/api/states', // 获取省/州
    cities: '/api/cities', // 获取城市
}

const post_urls = {
	login: '/api/user/login', // 登录
	register: '/api/user/register', // 注册
    captcha: '/api/system/captcha', // 验证码
    user_info: '/api/user/user_info', // 用户信息
    set_user_info: '/api/user/set_user_info', // 设置用户信息
    home: '/api/system/home', // 主页
    coins: '/api/system/coins', // 货币列表
    category: '/api/good/category', // 分类
    goods_list: '/api/good/getGoodsByCategory', // 商品列表
    goods_detail: '/api/good/detail', // 商品详情
    collect: '/api/good/fans', // 收藏
    collect_list: '/api/good/fans_goods', // 收藏列表
    addCart: '/api/shop/addCart', // 加入购物车
    cart_list: '/api/shop/cart', // 获取购物车列表
    editCart: '/api/shop/editCart', // 修改&删除购物车商品
    
    buyDirect: '/api/shop/buyDirect', // 单商品付款
    buyCart: '/api/shop/buyCart', // 购物车付款
    
    comment: '/api/good/comment', // 评论
    comment_list: '/api/good/comment_list', // 全部评论
    
    getAddress: '/api/address', // 获取地址列表
    createAddress: '/api/address/create', // 添加&编辑地址
    deleteAddress: '/api/address/delete', // 删除地址
    address_default: '/api/address/default', // 获取默认地址
    address_detail: '/api/address/detail', // 地址详情
    
    orderList: '/api/shop/orderList', // 订单列表
    orderDetail: '/api/shop/orderDetail', // 订单详情
    payOrder: '/api/shop/pay', // 支付订单
    
    leaveMessage: '/api/account/bind', // 留言
    changePassword: '/api/user/reset_auth', // 修改密码
    
    setting: '/api/system/setting', // 获取配置
}

export {
	base_url,
	upload_url,
    get_urls,
    post_urls
}
