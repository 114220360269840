import Vue from 'vue'
import VueRouter from 'vue-router'
import PageLayout from "@/layout/PageLayout"
import ArticleLayout from "@/layout/ArticleLayout"

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        redirect: '/404'
    },
    {
        path: '/404',
        name: '404 Not Found',
        component: () => import('../views/404.vue')
    },
    {
        path: '/',
        component: PageLayout,
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('../views/home')
            },
            // {
            //     path: '/login',
            //     name: 'login',
            //     component: () => import('../views/login')
            // },
            {
                path: '/product',
                name: 'product',
                component: () => import('../views/product/index')
            },
            {
                path: '/detail',
                name: 'detail',
                component: () => import('../views/product/detail')
            },
            {
                path: '/comment',
                name: 'comment',
                component: () => import('../views/product/comment')
            },
            {
                path: '/AccountSummary',
                name: 'Account Summary',
                component: () => import('../views/account/index')
            },
			{
			    path: '/MyOrders',
			    name: 'My Orders',
			    component: () => import('../views/account/MyOrders/index')
			},
			{
			    path: '/OrderDetail',
			    name: 'Order Detail',
			    component: () => import('../views/account/MyOrders/detail')
			},
			{
			    path: '/MyAddress',
			    name: 'My Address',
			    component: () => import('../views/account/MyAddress/index')
			},
			{
			    path: '/MyFavorites',
			    name: 'My Favorites',
			    component: () => import('../views/account/MyFavorites')
			},
			{
			    path: '/MyCoupon',
			    name: 'My Coupon',
			    component: () => import('../views/account/MyCoupon')
			},
			{
			    path: '/LeaveMessage',
			    name: 'Leave A Message',
			    component: () => import('../views/account/LeaveMessage')
			},
			{
			    path: '/MyProfile',
			    name: 'My Profile',
			    component: () => import('../views/account/MyProfile')
			},
			{
			    path: '/ChangePassword',
			    name: 'Change Password',
			    component: () => import('../views/account/ChangePassword')
			},
            {
                path: '/article',
                name: 'article',
                component: () => import('../views/article')
            }
        ]
    },
    {
        path: '',
        component: ArticleLayout,
        children: [
            {
                path: '/payment',
                name: 'payment',
                component: () => import('../views/product/payment')
            },
			{
			    path: '/AddAddress',
			    name: 'Add Address',
			    component: () => import('../views/account/MyAddress/Add')
			}
        ]
    }
]

// 解决冗余导航
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
    next()
})

export default router
