<template>
    <div id="app">
        <Header />
        <div class="main">
            <div class="main-wrapper">
                <page-nav />
                <router-view />
            </div>
        </div>
        <Footer />
        <float-menu />
        <div v-if="loading" class="loading-mask">
            <img class="loading-icon" src="@/assets/images/index/loading.gif" />
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            Header: () => import('@/components/Header'),
            Footer: () => import('@/components/Footer'),
            PageNav: () => import('@/components/PageNav'),
            FloatMenu: () => import('@/components/FloatMenu')
        },
        data() {
            return {
                
            }
        },
        computed: {
            loading() {
                return this.$store.state.loading
            }
        },
        methods: {
            
        }
    }
</script>

<style lang="scss">
    
</style>
